import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { APP_V2_APP_EVENTS, CommonDialog } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import { useAcceptOrDeclineShiftInvite } from "@src/appV2/Shifts/ShiftInvites/api/useAcceptOrDeclineShiftInvite";
import { ShiftInvite, ShiftInviteStatus } from "@src/appV2/Shifts/ShiftInvites/types";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

interface ShiftBookingConflictingInviteDialogProps {
  modalState: UseModalState;
  conflictingInvites: ShiftInvite[];
  openBookingWarningModal: () => void;
}

export function ShiftBookingConflictingInviteDialog(
  props: Readonly<ShiftBookingConflictingInviteDialogProps>
) {
  const { modalState, conflictingInvites, openBookingWarningModal } = props;
  const pluralizedInvite = pluralize("invite", conflictingInvites.length);
  const firstFacilityName = conflictingInvites[0]?.attributes.facility.name;
  const facilityNameText =
    conflictingInvites.length > 1 ? `${firstFacilityName} and other facilities` : firstFacilityName;
  const { mutateAsync: mutateAcceptOrDecline, isLoading: isProcessingDeclineShiftInvites } =
    useAcceptOrDeclineShiftInvite();
  const history = useHistory();

  return (
    <CommonDialog
      modalState={modalState}
      title={`You have pending shift ${pluralizedInvite}.`}
      actions={
        <>
          <Button
            fullWidth
            variant="outlined"
            disabled={isProcessingDeclineShiftInvites}
            onClick={() => {
              modalState.closeModal();
              history.push(TabRouterPath.OPEN_SHIFTS);
            }}
          >
            Go Back
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isProcessingDeclineShiftInvites}
            onClick={async () => {
              modalState.closeModal();
              openBookingWarningModal();
              try {
                await Promise.all(
                  conflictingInvites.map((invite) =>
                    mutateAcceptOrDecline({
                      shiftInviteId: invite.id,
                      status: ShiftInviteStatus.DECLINED,
                    })
                  )
                );
              } catch (error) {
                logError(APP_V2_APP_EVENTS.SHIFT_INVITE_ERROR, { error });
              }
            }}
          >
            Book Shift
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text variant="body2">
          If you proceed to book this shift, your {conflictingInvites.length} pending{" "}
          {pluralizedInvite} from {facilityNameText} will be automatically declined.
        </Text>
        <Text variant="body2">
          To accept the shift {pluralizedInvite} from {facilityNameText}, go back to your calendar,
          and tap the blue banner for shift invites.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
