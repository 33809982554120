import { Li, Span, Text, Ul } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { useGetMissingRequirementsForDate } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirementsForDate";
import { useGetQualificationForDocumentCheck } from "@src/appV2/Accounts/Documents/hooks/useGetQualificationForDocumentCheck/useGetQualificationForDocumentCheck";
import {
  APP_V2_APP_EVENTS,
  APP_V2_USER_EVENTS,
  logError,
  logEvent,
} from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { STATE_LICENSE_REQUIREMENT_ID, USER_EVENTS } from "@src/constants/index";
import { type Shift } from "@src/lib/interface/src";
import pluralize from "pluralize";
import { useMemo } from "react";

import { MISSING_DOCS_ALERT_INFO_MESSAGES } from "./constants";
import { CbhDocument, ShiftItemCategory } from "../model";

interface MissingDocumentsItemProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onConfirm: () => void;
  missingDocuments: Set<CbhDocument>;
  category: ShiftItemCategory;
  expiringInFuture: boolean;
  shift: Shift;
}
export function MissingDocumentsAlert(props: MissingDocumentsItemProps) {
  const { isOpen, onDidDismiss, onConfirm, missingDocuments, expiringInFuture, shift } = props;

  const { mutateAsync: getMissingRequirementsForDate } = useGetMissingRequirementsForDate();

  const worker = useDefinedWorker();

  const { getQualificationForDocumentCheck } = useGetQualificationForDocumentCheck();

  const workerPreferenceQualification = worker?.preference?.qualification;

  const { visibleDocumentsCount, missingDocsInfo, hasMoreDocuments } = useMemo(() => {
    const isPlural = missingDocuments.size > 1;
    const hasMoreDocuments = missingDocuments.size > 6;

    const visibleDocs: CbhDocument[] = [];
    const invisibleDocs: CbhDocument[] = [];

    for (const requirement of missingDocuments) {
      if (requirement.reqId === STATE_LICENSE_REQUIREMENT_ID) {
        visibleDocs.push({ ...requirement, name: "State License (upload as Miscellaneous)" });
      } else if (requirement.visibleToHCP) {
        visibleDocs.push(requirement);
      } else {
        invisibleDocs.push(requirement);
      }
    }

    return {
      visibleDocumentsCount: visibleDocs.length,
      missingDocsInfo: { visibleDocs, invisibleDocs, isPlural, expiringInFuture },
      hasMoreDocuments,
    };
  }, [expiringInFuture, missingDocuments]);

  function determineVisibleDocsMessage() {
    if (expiringInFuture) {
      return missingDocsInfo.visibleDocs.length > 1
        ? MISSING_DOCS_ALERT_INFO_MESSAGES.MULTIPLE_VISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE
        : MISSING_DOCS_ALERT_INFO_MESSAGES.SINGLE_VISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE;
    } else {
      return missingDocsInfo.isPlural
        ? MISSING_DOCS_ALERT_INFO_MESSAGES.MULTIPLE_DOCS_MISSING_CURRENTLY_MESSAGE
        : MISSING_DOCS_ALERT_INFO_MESSAGES.SINGLE_DOC_MISSING_CURRENTLY_MESSAGE;
    }
  }

  function determineInvisibleDocsMessage() {
    let invisibleDocsMessage = "";

    const visibleDocsCount = missingDocsInfo.visibleDocs.length;
    const { invisibleDocs } = missingDocsInfo;

    if (expiringInFuture) {
      if (visibleDocsCount && invisibleDocs.length) {
        invisibleDocsMessage =
          invisibleDocs.length > 1
            ? MISSING_DOCS_ALERT_INFO_MESSAGES.MULTIPLE_INVISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE
            : MISSING_DOCS_ALERT_INFO_MESSAGES.SINGLE_INVISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE;
      } else if (invisibleDocs.length) {
        invisibleDocsMessage =
          invisibleDocs.length > 1
            ? MISSING_DOCS_ALERT_INFO_MESSAGES.ONLY_MULTIPLE_INVISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE
            : MISSING_DOCS_ALERT_INFO_MESSAGES.ONLY_SINGLE_INVISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE;
      }
    } else if (invisibleDocs.length > 0) {
      invisibleDocsMessage =
        MISSING_DOCS_ALERT_INFO_MESSAGES.MESSAGE_FOR_INVISIBLE_DOCS_MISSING_CURRENTLY;
    }

    return invisibleDocsMessage;
  }

  const notifySupport = async () => {
    if (missingDocsInfo.invisibleDocs.length > 0) {
      try {
        await getMissingRequirementsForDate({
          hcfId: shift.facilityId ?? "",
          date: shift.end ?? "",
          qualification: getQualificationForDocumentCheck({
            shiftQualificationRequirement: shift.agentReq ?? "",
            selectedWorkerQualification: workerPreferenceQualification ?? "",
          }),
          notifySupport: true,
        });
      } catch (error) {
        logError(APP_V2_APP_EVENTS.MISSING_DOCUMENTS_ALERT_NOTIFY_SUPPORT_FAILURE, {
          error,
          metadata: {
            hcfId: shift.facilityId ?? "",
            shiftId: shift._id ?? "",
            date: shift.end ?? "",
            qualification: shift.agentReq ?? "",
          },
        });
      }
    }
  };

  useLogEffect(APP_V2_USER_EVENTS.VIEWED_MISSING_DOCUMENTS_ALERT, {
    shiftId: shift._id ?? "",
    facilityId: shift.facilityId ?? "",
    visibleMissingDocuments: missingDocsInfo.visibleDocs.map(
      (missingRequirement) => missingRequirement.reqId
    ),
    invisibleMissingDocuments: missingDocsInfo.invisibleDocs.map(
      (missingRequirement) => missingRequirement.reqId
    ),
  });

  return (
    <Dialog open={isOpen} onClose={onDidDismiss}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        You're missing{" "}
        <Span color={(theme) => theme.palette.primary.main}>{missingDocuments.size}</Span>{" "}
        {pluralize("document", missingDocuments.size)}
      </DialogTitle>

      <DialogContent data-testid="missing-docs-msg">
        <DialogContentText>
          <Stack spacing={4} sx={{ marginTop: 1 }}>
            {missingDocsInfo.visibleDocs.length > 0 && (
              <Stack direction="column" spacing={2}>
                <Stack spacing={1}>
                  <Text variant="h3" bold>
                    Action Required
                  </Text>
                  <Text>{determineVisibleDocsMessage()}</Text>
                  <br />
                  <Ul>
                    {missingDocsInfo.visibleDocs.slice(0, 6).map((document) => (
                      <Text variant="body2" bold key={document.reqId}>
                        <Li>{document.name}</Li>
                      </Text>
                    ))}
                  </Ul>
                </Stack>
                <Divider />
              </Stack>
            )}
            {missingDocsInfo.invisibleDocs.length > 0 && missingDocsInfo.visibleDocs.length < 6 && (
              <Stack spacing={1}>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text variant="h3" bold>
                    Under Review
                  </Text>
                </Stack>
                <Text>{determineInvisibleDocsMessage()}</Text>
                <br />
                <Ul>
                  {missingDocsInfo.invisibleDocs
                    .slice(0, 6 - missingDocsInfo.visibleDocs.length)
                    .map((document) => (
                      <Text variant="body2" bold key={document.reqId}>
                        <Li>{document.name}</Li>
                      </Text>
                    ))}
                </Ul>
                <br />
                <Text>
                  {
                    MISSING_DOCS_ALERT_INFO_MESSAGES.MESSAGE_FOR_INVISIBLE_DOCS_MISSING_CURRENTLY_SUFFIX
                  }
                </Text>
              </Stack>
            )}
            {hasMoreDocuments && (
              <Text>
                Click to upload your documents and view the remaining missing requirements.
              </Text>
            )}
          </Stack>
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant={visibleDocumentsCount > 0 ? "outlined" : "contained"}
          onClick={async () => {
            if (!visibleDocumentsCount) {
              await notifySupport();
            }
            onDidDismiss();
            logEvent(USER_EVENTS.TAPPED_CANCEL_MISSING_DOCS_POPUP_BUTTON);
          }}
        >
          {visibleDocumentsCount > 0 ? "Cancel" : "Okay"}
        </Button>
        {visibleDocumentsCount > 0 && (
          <Button
            onClick={async () => {
              logEvent(APP_V2_USER_EVENTS.MISSING_DOCUMENTS_ALERT_UPLOAD_BUTTON_CLICKED, {
                workplaceId: shift.facilityId,
                shiftId: shift._id,
                shiftQualification: shift.agentReq,
                shiftEnd: shift.end,
                visibleDocumentsCount: visibleDocumentsCount,
                invisibleDocumentsCount: missingDocsInfo.invisibleDocs.length,
                missingDocuments: missingDocsInfo,
              });
              await notifySupport();
              onConfirm();
            }}
            variant="contained"
          >
            Upload Docs
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
