import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface OverworkBookingAlertProps {
  isOpen: boolean;
  onDidDismiss: () => void;
}

export function OverworkBookingAlert(props: OverworkBookingAlertProps) {
  const { isOpen, onDidDismiss } = props;

  return (
    <Dialog open={isOpen} onClose={onDidDismiss}>
      <DialogTitle>Oops, you can't book this shift!</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          Consistent with our Terms of Service, you may not book back-to-back shifts resulting in
          over 17 hours worked in a row.
        </DialogContentText>
        <DialogContentText paragraph>
          Your other booked shift(s), combined with this shift, would exceed that limit.
        </DialogContentText>
        <DialogContentText paragraph>
          We are doing this to help make sure our marketplace's users have a positive experience.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onDidDismiss();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
