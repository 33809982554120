import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type WaitingStripeResponseAlertProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

export const WaitingStripeResponseAlert = (props: WaitingStripeResponseAlertProps) => {
  const { isOpen, onDidDismiss } = props;

  return (
    <Dialog open={isOpen} onClose={onDidDismiss}>
      <DialogTitle>Stripe is currently verifying your account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can start booking shifts once verification is complete.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDidDismiss} variant="contained" fullWidth>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
