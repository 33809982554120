import { Text } from "@clipboard-health/ui-react";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Li, Ul } from "@src/appV2/lib/Lists";
import pluralize from "pluralize";

const TRUNCATED_DOCUMENT_COUNT = 6;

interface PendingDocumentsAlertProps {
  isOpen: boolean;
  pendingDocuments: Set<string>;
  onConfirm: () => void;
  onDidDismiss: () => void;
}

export function PendingDocumentsAlert(props: PendingDocumentsAlertProps) {
  const { pendingDocuments, isOpen, onDidDismiss, onConfirm } = props;

  const truncatedDocuments = Array.from(pendingDocuments).slice(0, TRUNCATED_DOCUMENT_COUNT);
  const hasMoreDocuments = pendingDocuments.size > TRUNCATED_DOCUMENT_COUNT;

  return (
    <Dialog open={isOpen} onClose={onDidDismiss}>
      <DialogTitle sx={{ textAlign: "center" }}>
        <ErrorIcon color={"error"} fontSize="large" />
        <br />
        <span>{pendingDocuments.size}</span> {pluralize("Document", truncatedDocuments.length)}{" "}
        Awaiting Approval
      </DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          To complete your booking, the following {pluralize("document", truncatedDocuments.length)}{" "}
          must be approved:
        </DialogContentText>

        <Ul>
          {truncatedDocuments.map((documentName) => (
            <Li key={documentName}>
              <Text>{documentName}</Text>
            </Li>
          ))}
        </Ul>

        {hasMoreDocuments ? (
          <DialogContentText paragraph>
            Go to the documents page to see the full list.
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDidDismiss} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained">
          View {pluralize("Doc", truncatedDocuments.length)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
